<template>
  <div>
    <KioskForm />
  </div>
</template>

<script>
import KioskForm from "../../components/Kiosk/KioskForm";
export default {
  components: {
    KioskForm
  }
};
</script>
